<template>
  <div>
    <v-flex tag="h1">
      Dagens kode
    </v-flex>
    <br>
    <v-flex class=text-xs-center v-if="loading">
      <v-progress-circular :size="50" :width="5" indeterminate class="sirkus-red-color"></v-progress-circular>
    </v-flex>
    <v-flex v-if="code">
       Dagens kode er <p class="title">{{code}}</p>
    </v-flex>
    <v-flex v-if="error">
       {{error}}
    </v-flex>
  </div>
</template>
<script>
const axios = require('axios');

export default {
  data: function() {
    return {
      code: '',
      error: '',
      loading: false,
      retries: 0
    };
  },
  created: function() {
    this.getCode();
  },

  methods: {
    getCode: function(){
    this.loading=true;
    axios.get(`/hello/code/${this.$route.params.code}/`)
      .then((response) => {
        if (response.status===200){
          this.loading=false;
          this.code = response.data.code;
          this.error = response.data.text;
        }
        else if(this.retries >= 50) {
          this.loading=false;
          this.error = 'Noe gikk galt, prøv igjen om litt.';
        }
        else {
          this.retries++;
          this.getCode();
        }
      })
      .catch((response) => {
        this.loading=false;
        this.error = 'Noe gikk galt, prøv igjen om litt.';
      })
  }
}
}
</script>
