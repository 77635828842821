<template>
  <div>
    <!--
    <v-navigation-drawer
      v-model="drawer"
      fixed
      right
      app
    >
      <v-list dense>
        <v-list-tile>
          <v-list-tile-action>
            <v-icon>home</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Hjem</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-action>
            <v-icon>contact_mail</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Om oss</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>-->
    <v-toolbar style="background-color: white;" fixed app>
      <v-toolbar-title><router-link to="/"><img src="/static/Flexiwork_RGB.png" alt="Flexiwork" height="64px"></router-link></v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link to="/privacy" class="sirkus-red-color">Kjøpsvilkår</router-link>
      <!--<v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>-->
    </v-toolbar>


    <v-content>
      <v-carousel height="250px" v-if="$route.path == '/'">
        <v-carousel-item
          v-for="(img,i) in images"
          :key="i"
          :src="imagePath(img.bilde)"
        >
          <v-responsive dark>
              <v-container fill-height>
                <v-layout align-center column fill-height>
                  <v-flex>
                    <!--<h3 class="display-3">{{ img.navn }}</h3>-->
                    <p class="subheading">{{ img.bilde_tekst }}</p>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-responsive>
        </v-carousel-item>
      </v-carousel>

      <v-container>
        <router-view :tjenester="tjenester"></router-view>
      </v-container>
     <!--<img src="/static/sau.jpg">-->
    </v-content>
  </div>
</template>
<script>
const axios = require('axios');

export default {
  data: function() {
    return {
      drawer: false,
      tjenester: [],
      images: [],
    };
  },
  created: function() {
    axios.get('/hello/all/')
      .then(response => {
        this.tjenester = response.data.tjenester;
      });
    axios.get('/hello/slideshow/')
      .then(response => {
        this.images = response.data.images;
      });
  },
  methods: {
    imagePath: function(path) {
      var rightPath = path.split('/').reverse()[0];
      var imgShortPath = '/static/' + rightPath;
      return imgShortPath;
    },
  }
}
</script>
<style>
a, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}
</style>
