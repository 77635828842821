import Vue from 'vue';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css' 
import VueRouter from 'vue-router'
// Helpers
import colors from 'vuetify/es5/util/colors'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.use(Vuetify,{ 
  theme: {
    primary: '#E53935', // #E53935
    secondary: colors.red.lighten4, // #FFCDD2
    accent: colors.indigo.base // #3F51B5
  },
});

Vue.use(VueRouter)

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.config.productionTip = false;

import App from './App.vue';


const routes = [
  { path: '/', component: require('./FrontPage.vue').default },
  { path: '/info/:id/', component: require('./InfoPage.vue').default },
  { path: '/info/:id/booking/', component: require('./BookingPage.vue').default },
  { path: '/info/:id/:stid/booking/', component: require('./BookingPage.vue').default },
  { path: '/checkout/', component: require('./Checkout.vue').default },
  { path: '/confirmation/', component: require('./Confirmation.vue').default },
  { path: '/privacy/', component: require('./Personvern.vue').default },
  { path: '/code/:code/', component: require('./Code.vue').default },
];

const router = new VueRouter({
  routes // short for `routes: routes`
});

export default () => {
    /* eslint-disable no-new */
    new Vue({
      el: "#app",
      router,
      render: h => h(App)
    });
};