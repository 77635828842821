<template>
  <v-container
    id="grid"
    fluid
    grid-list-sm
  >

    <v-flex tag="h1" v-if="!tjeneste">
      Tjeneste name found
    </v-flex>

    <v-layout row wrap v-else>
      <v-flex tag="h1">
        {{tjeneste.name}}
      </v-flex>
      <v-flex d-flex xs12>
        <v-card flat>
          <v-card-text v-if="tjeneste" v-html="tjeneste.description" class="tjeneste">
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs12 tag="h3">
        Pris {{tjeneste.price.replace(/\./g, ',')}},- (inkl. MVA)
      </v-flex>
      
      <v-flex>
        <router-link :to="'/info/' + tjeneste.id + '/booking/'">
          <v-btn class="sirkus-red" dark v-if="!tjeneste.enabledSubtjeneste">Kjøp</v-btn>
        </router-link>
        <v-layout row wrap v-if="tjeneste.enabledSubtjeneste">
          <v-flex xs12 sm6 md4 v-for="st in subtjenester" :key="st.id">
            <v-card style="margin: 10px;">
              <v-card-title>{{st.name}}</v-card-title>
              <v-card-text>{{st.description}}</v-card-text>
              <v-card-actions>
                <v-btn color="sirkus-red" dark @click="selectSubtjeneste(st)" style="width: 100%">Kjøp</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      
    </v-layout>
  </v-container>
</template>
<script>
const axios = require('axios');

export default {
  data: () => ({
    subtjenester: [],
  }),
  props: {
    tjenester: Array,
  },
  computed: {
    tjeneste: function(){
      return this.tjenester.find(t => t.id === Number(this.$route.params.id));
    },
  },
  watch: {
    tjeneste: function () {
      axios.get(`/hello/${this.tjeneste.id}/`).then(({data}) => 
        (this.subtjenester = data.subtjenester)
      );
    }
  },
  mounted: function() {
    if (this.tjeneste)
      axios.get(`/hello/${this.tjeneste.id}/`).then(({data}) => 
        (this.subtjenester = data.subtjenester)
      );
  },
  methods: {
    selectSubtjeneste: function(subtjeneste) {
      this.$router.push(`/info/${this.tjeneste.id}/${subtjeneste.id}/booking/`);
    }
  }
}
</script>
<style>
.tjeneste a {
  text-decoration: blue underline;
  color: blue;
}
.tjeneste img {
    max-width: 100%;
    max-height: 100%;
    margin: 10px 0 10px 0;
}
</style>
