<template>
  <v-container id="grid" fluid grid-list-sm>
    <v-flex v-if="!tjeneste">Fant ikke tjenesten</v-flex>
    <v-layout row wrap v-else>
      <v-flex xs12 tag="h1">{{tjeneste.name}}</v-flex>
      <v-flex xs12 v-if="!booked">
        <v-progress-circular indeterminate class="sirkus-red-color"></v-progress-circular>
      </v-flex>
      <v-flex xs12 sm6 v-if="booked" ma-2>
        <!-- 864e5 == 86400000 == 24*60*60*1000 -->
        <v-date-picker
          v-model="picker"
          first-day-of-week="1"
          :min="new Date(Date.now() - 864e5).toISOString()"
          :max="new Date(Date.now() + 864e5*30*3).toISOString()"
          :allowed-dates="isDateBooked"
          header-color="sirkus-red"
          color="sirkus-red"
          ref="picker"
          @change="tooltipInit"
          :type="(tjeneste.booking_period === 'm' ? 'month' : 'date')"
        ></v-date-picker>
      </v-flex>
      <v-flex xs12 sm6 v-if="booked && bookedStart && bookedEnd && tjeneste.booking_period === 'h'" ma-2>
        <VueCtkDateTimePicker
          v-model="time"
          label="Velg starttid"
          color="#b21218"
          button-color="#b21218"
          format="HH:mm"
          formatted="HH:mm"
          minute-interval="60"
          input-size="lg"
          :disabled-hours="disabledTime"
          only-time
        ></VueCtkDateTimePicker>
        <VueCtkDateTimePicker
          v-model="endTime"
          label="Velg sluttid"
          color="#b21218"
          button-color="#b21218"
          format="HH:mm"
          formatted="HH:mm"
          minute-interval="60"
          input-size="lg"
          :disabled-hours="disabledEndTime"
          only-time
        ></VueCtkDateTimePicker>
      </v-flex>
      <v-flex>
        <v-btn class="sirkus-red" dark @click="addBooking">Kjøp</v-btn>
      </v-flex>
    </v-layout>
    <v-card ref="tooltip" style="display: none;">
      <v-card-title>Fullt</v-card-title>
    </v-card>
  </v-container>
</template>

<script>
//import { all } from 'q';
const axios = require("axios");

export default {
  data: function() {
    return {
      picker: null,
      booked: undefined,
      bookedStart: undefined,
      bookedEnd: undefined,
      time: '',
      endTime: '',
      disabledTime: [],
      disabledEndTime: []
    };
  },
  props: {
    tjenester: Array
  },
  computed: {
    tjeneste: function() {
      return this.tjenester.find(t => t.id === Number(this.$route.params.id));
    },
    subtjeneste: function() {
      return Number(this.$route.params.stid);
    },
  },
  created: function() {
    this.loadBookings();
  },
  watch: {
    picker: function() {
      if (this.tjeneste.booking_period === 'h') {
        this.getFirstAvailableTime();
        this.disabledHoursStart();
        this.disabledHoursEnd();
      }
    },
    time: function() {
      this.disabledHoursEnd();
      this.getFirstAvailableEndTime();
    },
    endTime: function() {
      this.disabledHoursStart();
    }
  },
  methods: {
    disabledHoursStart: function () {
      var start=[];
      for (const i of [...Array(24).keys()]) {
        if (!this.allowedHoursStart(i)) {
          i<10 ? start[i]= `0${i}` : start[i]=`${i}`;
        }
      } 
      this.disabledTime=start;
    },
    disabledHoursEnd: function () {
      var end=[];
      let bookedStartAtPicker=this.bookedStart[this.picker];
      //disable all hours after next start time
      if (bookedStartAtPicker != undefined){
        for (const n of bookedStartAtPicker){
          if (this.time < n.substr(0,5)){
            let nextStartTime=Number(n.substr(0,2));
            let arr = this.range(24-nextStartTime,nextStartTime+1);
            for (const i of arr) {
              i<10 ? end[i]= `0${i}` : end[i]=`${i}`;
            }
          }
        }
      }
      for (const i of [...Array(24).keys()]) {
        //Disables all hours before start time
        if (`0${i}:00` == this.time){
          end[i]= `0${i}`;
          for (const k of [...Array(i).keys()]) {
            end[k] = `0${k}`;
          }
        }
        else if (`${i}:00` == this.time){
          end[i]=`${i}`;
          for (const k of [...Array(i).keys()]) {
            k < 10? end[k] = `0${k}` : end[k] = `${k}`;
          }
        }
        //disables all other times that are not allowed according to allowedHoursEnd function
        else if (!this.allowedHoursEnd(i)) {
          i<10 ? end[i]= `0${i}` : end[i]=`${i}`;
        }
      }
      this.disabledEndTime=end;
    },
    range: function (size, startAt) {
      //return [...Array(size).keys()].map(i => i + startAt);
      return Array(size).join(0).split(0).map(function(val, id) {return id+startAt});
    },
    allowedHoursStart: function (v) {
      return (v > 5 && v < 23) && (
        !this.booked[this.picker] ||
        !this.booked[this.picker].some(time => (
          (Number(time.substr(0, 2))) === v
        )) 
      )&& (!this.bookedStart[this.picker] || !this.bookedStart[this.picker].some(time => (
          (Number(time.substr(0, 2))) === v)))
    },
    allowedHoursEnd: function (v) {
      return (v > 6 && v < 24) && (
        !this.booked[this.picker] ||
        !this.booked[this.picker].some(time => (
          (Number(time.substr(0, 2))) === v
        )) 
      ) && (!this.bookedEnd[this.picker] || !this.bookedEnd[this.picker].some(time => (
          (Number(time.substr(0, 2))) === v))
      )
    },
    /*allowedMinutes: function (v) {
      return (v === 60);
    },*/
    isDateBooked: function(val) {
      if (Number(val.slice(8, 10)) == 1) this.$nextTick(this.tooltipInit);
      if (this.tjeneste.booking_period === 'h') return true;
      return (
        (this.booked[val] !== undefined ? this.booked[val] : 0) <
        this.tjeneste.available_spaces
      );
    },
    getFirstAvailable: function() {
      const startDate = new Date(Date.now());
      // Return a datestring of the first available date the next 31 days
      for (const i of [...Array(31).keys()]) {
        const date = new Date(startDate.getTime() + 864e5 * i);
        const dateString = date.toISOString().substr(0, 10);
        if (this.isDateBooked(dateString)) {
          if (this.tjeneste.booking_period === 'm') {
            this.picker = dateString.slice(0, 7);
            return;
          } else if (this.tjeneste.booking_period === 'h'){
            this.disabledHoursStart();
            this.disabledHoursEnd();
            if (this.getFirstAvailableTime()) {
              return;
            }
          } else {
            this.picker = dateString;
            return;
          }
        }
      }
    },
    getFirstAvailableTime: function() {
      for (const i of [...Array(24).keys()]) {
        if (this.allowedHoursStart(i)) {
          i<10 ? this.time= `0${i}:00` : this.time=`${i}:00`;
          this.getFirstAvailableEndTime();
          return true;
        }
      }
      this.time = undefined;
      return false;
    },
    getFirstAvailableEndTime: function() {
      const startTime=this.time.substr(0,2);
      //startTime.substr(0,1)==0 ? k = Number(startTime.substr(1,2))+1 : k = Number(startTime.substr(0,2))+1;
      let k = Number(startTime.substr(0,2))+1;
      for (k of [...Array(24).keys()].slice(k)) {
        if (this.allowedHoursEnd(k)) {
          k < 10 ? this.endTime = `0${k}:00` : this.endTime = `${k}:00`;
          return;
        }
      } 
    },
    addBooking: function() {
      let bookings = JSON.parse(localStorage.bookings || null) || [];

      /*
      // For multiple bookings
      bookings.push({
        tjeneste: this.tjeneste.id,
        date: this.picker,
      });
      */
      bookings = [
        {
          ...this.tjeneste,
          tjeneste: this.tjeneste.id,
          date: this.picker,
          time: this.time,
          endTime: this.endTime,
          subtjeneste: this.subtjeneste,
        }
      ];

      localStorage.bookings = JSON.stringify(bookings);

      this.$router.push("/checkout/");
    },
    loadBookings: function() {
      if (this.$route.params.stid) {
        axios.get(`/hello/subtjenestebookings/${this.$route.params.stid}/`).then(response => {
          if (this.tjeneste.booking_period === 'h') {
            this.booked = response.data.bookings;
            this.bookedStart = response.data.bookingsStart;
            this.bookedEnd = response.data.bookingsEnd;
          }
          else {
            this.booked=response.data.bookings;
          }
          this.getFirstAvailable();
        });
      } else {
        axios.get(`/hello/bookings/${this.$route.params.id}/`).then(response => {
          if (this.tjeneste.booking_period === 'h') {
            this.booked = response.data.bookings;
            this.bookedStart = response.data.bookingsStart;
            this.bookedEnd = response.data.bookingsEnd;
          }
          else {
            this.booked=response.data.bookings;
          }
          this.getFirstAvailable();
        });
      }
    },
    tooltipInit: function() {
      const what = this.$refs[
        "picker"
      ].$el.children[1].children[0].children[1].getElementsByTagName("button");
      const tooltip = this.$refs["tooltip"].$el;

      [...what]
        .filter(el => el.disabled)
        .forEach(el => {
          el.parentNode.addEventListener("click", e => {
            const bounding = el.parentNode.getBoundingClientRect();
            tooltip.setAttribute(
              "style",
              `display: block; position: absolute; top: ${bounding.top -
                110}; left: ${bounding.left - 5}`
            );
          });
          el.parentNode.addEventListener("mouseenter", e => {
            const bounding = el.parentNode.getBoundingClientRect();
            tooltip.setAttribute(
              "style",
              `display: block; position: absolute; top: ${bounding.top -
                110}; left: ${bounding.left - 5}`
            );
          });
          el.parentNode.addEventListener("mouseleave", e => {
            tooltip.setAttribute("style", `display: none;`);
          });
        });
    }
  }
};
</script>
<style>
</style>
