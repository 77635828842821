<template>
  <div>
    <v-layout row wrap class="full-height">
      <v-flex xs6 sm6 md4 lg4 xl4 v-for="tjeneste in tjenester" :key="tjeneste.id">
        <v-card :to="`/info/${tjeneste.id}/`" dark class="ma-1 card-align-text sirkus-red">
          <v-card-text class="card-text-center front-page-button" :ref="tjeneste.id">
              {{tjeneste.name}}
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: {
    tjenester: Array,
  },
  watch: {
    tjenester: function() {
      // If the button overflows use a smaller font size
      if (this.tjenester) {
        this.$nextTick(() => {
          const resizer = () => {
            [...Array(this.tjenester.length).keys()].map(n => this.$refs[n+1][0]).forEach(e => {
              e.style.fontSize = '24px';
              if (e.scrollWidth > e.parentElement.clientWidth) {
                e.style.fontSize = Math.max(24 - ((e.scrollWidth / e.parentElement.clientWidth)-1) * 24, 14) + 'px';
              }
            });
            var addEvent = function (el, type, fn) {
              if (el.addEventListener)
                el.addEventListener(type, fn, false);
              else
                el.attachEvent('on' + type, fn);
            };
            resizer();
            addEvent(window, 'resize', resizer);
            addEvent(window, 'orientationchange', resizer);
          }
        })
      }
    }
  }
}
</script>
<style>
.full-height .flex {
  display: flex;
}
.full-height .flex > .v-card{
  flex: 1 1 auto;
  overflow: hidden;
}

.card-align-text {
  display: flex;
  align-items: center;
}
.card-text-center {
  text-align: center;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}
</style>
