<template>
  <v-container>
    <v-layout row wrap>
      <v-flex tag="h1">Bestill</v-flex>

      <form @submit.prevent="confirm" autocomplete="on">
        <v-flex>
          <v-flex>
            <v-text-field
              label="Epost"
              type="email"
              name="email"
              v-model="email"
              style="width: 290px"
              placeholder="me@example.com"
              required
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Telefon"
              v-model="tlf"
              type="tel"
              name="phone"
              style="width: 290px"
              placeholder="+47 99999999"
              autocomplete="tel"
              required
            ></v-text-field>
          </v-flex>
          <v-flex v-if="bookings.some(b => b.carId)">
            <v-text-field label="Bilnummer" v-model="carId" style="width: 290px" autocomplete="off"></v-text-field>
          </v-flex>
          <v-flex v-for="booking in bookings.filter(b => b.setNumber)" :key="booking.name">
            <v-text-field
              :label="`Antall for ${booking.name}`"
              v-model="booking.number"
              min="1"
              type="number"
              style="width: 290px"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Kampanjekode"
              v-model="voucher"
              style="width: 290px"
              autocomplete="off"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <p>Viktig: Vi lagrer midlertidig epost og telefonnummer koblet til bestillingen frem til den er fullført eller kansellert for å kunne sende kvittering og kode til deg etter gjennomført kjøp.</p>
          </v-flex>
          <v-flex>
            <v-checkbox required>
              <div slot="label">
                Jeg har lest og godtatt
                <router-link to="/privacy">
                  <span style="text-decoration: underline">vilkår for bruk</span>
                </router-link>
              </div>
            </v-checkbox>
          </v-flex>
        </v-flex>

        <v-flex xs12>
          <v-flex xs12>
            <v-btn class="sirkus-red" dark type="submit" :loading="block">Bekreft bestillingen</v-btn>
          </v-flex>
        </v-flex>
        <v-flex>
          <p class="title">{{response}}</p>
        </v-flex>
      </form>
    </v-layout>
  </v-container>
</template>
<script>
const axios = require("axios");

export default {
  data: function() {
    return {
      bookings: [],
      response: "",
      email: "",
      tlf: "",
      voucher: "",
      carId: "",
      accepted: false,
      block: false,
    };
  },
  mounted: function() {
    this.bookings = JSON.parse(localStorage.bookings || null) || [];
    this.bookings.forEach(b => b.number = 1);
  },
  methods: {
    confirm: function() {
      this.block = true;

      axios({
        url: `/hello/book/`,
        method: "post",
        xsrfCookieName: "csrftoken",
        xsrfHeaderName: "X-CSRFToken",
        data: {
          bookings: this.bookings,
          email: this.email,
          tlf: this.tlf,
          voucher: this.voucher,
          carId: this.carId,
        }
      }).then(response => {
        if (response.data.type === "success") {
          localStorage.bookings = "";
          if (response.data.url) {
            window.location.href = response.data.url;
            this.response = "Du vil bli sent til betalingssiden straks";
          } else {
            this.response =
              response.data.text ||
              "Noe gikk galt, vennligst prøv igjen om noen minutter";
          }
        } else {
          this.response =
            response.data.text ||
            "Noe gikk galt, vennligst prøv igjen om noen minutter";
        }
      })
      .finally(() => {
        this.block = false;
      });
    }
  }
};
</script>
