<template>
  <v-container>
    <v-layout row wrap>
      <v-flex tag="h1" v-if="accepted">
        Fullført
      </v-flex>
      <v-flex tag="h1" v-else>
        Avbrutt
      </v-flex>
      <v-flex d-flex xs12>
        <v-layout column>
          <v-flex>
            <v-card flat>
              <v-card-text v-if="accepted">
                <p>
                  Betalingen din var vellykket!
                </p>
                <p>
                  Se sms og email for informasjon om hvordan du benytter deg av tjenesten. 
                </p>
              </v-card-text>
              <v-card-text v-else>
                Bestillingen din ble avbrutt. 
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  computed: {
    accepted: function() {
      return this.$route.query.accepted === '1';
    },
  },
}
</script>